// Fonts

@font-face {
  font-family: 'MaisonNeue-Book';
  src:
  url('../fonts/MaisonNeue-Book.woff2') format('woff2'),
  url('../fonts/MaisonNeue-Book.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'MaisonNeue-BookItalic';
  src:
  url('../fonts/MaisonNeue-BookItalic.woff2') format('woff2'),
  url('../fonts/MaisonNeue-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'MaisonNeue-Light';
  src:
  url('../fonts/MaisonNeue-Light.woff2') format('woff2'),
  url('../fonts/MaisonNeue-Light.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'MaisonNeue-LightItalic';
  src:
  url('../fonts/MaisonNeue-LightItalic.woff2') format('woff2'),
  url('../fonts/MaisonNeue-LightItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
