.o-col--full {
	@include col(1);
}

.o-col--half {
	@include col(1/2);
	&:not(.o-col--all) {
		@include mq($br-small) {
			@include col(1);
			//@include col--padding-bottom();
		}
	}
}

.o-col--third {
	@include col(1/3);
	&:not(.o-col--all) {
		@include mq($br-small) {
			@include col(1);
			@include col--padding-bottom();
		}
	}
}

.o-col--fourth {
	@include col(1/4);
	&:not(.o-col--all) {
		@include mq($br-medium) {
			@include col(1/3);
			@include col--padding-bottom();
		}
		@include mq($br-medium) {
			@include col(1/2);
			@include col--padding-bottom();
		}
		@include mq($br-small) {
			@include col(1);
			@include col--padding-bottom();
		}
	}
}


.o-col--two-thirds {
	@include col(2/3);
	&:not(.o-col--all) {
		@include mq($br-small) {
			@include col(1);
			@include col--padding-bottom();
		}
	}
}

.o-col--two-thirds--mobile {
	@include mq($br-small) {
		padding-left: $padding/2 !important;
      	padding-right: $padding/2 !important;
      	width: 66.66% !important;
	}
}

.o-col-12 { @include col(12/12); }
.o-col-11 { @include col(11/12); }
.o-col-10 { @include col(10/12); }
.o-col-9 { @include col(9/12); }
.o-col-8 { @include col(8/12); }
.o-col-7 { @include col(7/12); }
.o-col-6 { @include col(6/12); }
.o-col-5 { @include col(5/12); }
.o-col-4 { @include col(4/12); }
.o-col-3 { @include col(3/12); }
.o-col-2 { @include col(2/12); }
.o-col-1 { @include col(1/12); }
.o-col-12--no-padding { @include col(12/12, false); }
.o-col-11--no-padding { @include col(11/12, false); }
.o-col-10--no-padding { @include col(10/12, false); }
.o-col-9--no-padding { @include col(9/12, false); }
.o-col-8--no-padding { @include col(8/12, false); }
.o-col-7--no-padding { @include col(7/12, false); }
.o-col-6--no-padding { @include col(6/12, false); }
.o-col-5--no-padding { @include col(5/12, false); }
.o-col-4--no-padding { @include col(4/12, false); }
.o-col-3--no-padding { @include col(3/12, false); }
.o-col-2--no-padding { @include col(2/12, false); }
.o-col-1--no-padding { @include col(1/12, false); }

@include mq($br-large) {
	.o-col-lg-12 { @include col(12/12); }
	.o-col-lg-11 { @include col(11/12); }
	.o-col-lg-10 { @include col(10/12); }
	.o-col-lg-9 { @include col(9/12); }
	.o-col-lg-8 { @include col(8/12); }
	.o-col-lg-7 { @include col(7/12); }
	.o-col-lg-6 { @include col(6/12); }
	.o-col-lg-5 { @include col(5/12); }
	.o-col-lg-4 { @include col(4/12); }
	.o-col-lg-3 { @include col(3/12); }
	.o-col-lg-2 { @include col(2/12); }
	.o-col-lg-1 { @include col(1/12); }
	.o-col-lg-12--no-padding { @include col(12/12, false); }
	.o-col-lg-11--no-padding { @include col(11/12, false); }
	.o-col-lg-10--no-padding { @include col(10/12, false); }
	.o-col-lg-9--no-padding { @include col(9/12, false); }
	.o-col-lg-8--no-padding { @include col(8/12, false); }
	.o-col-lg-7--no-padding { @include col(7/12, false); }
	.o-col-lg-6--no-padding { @include col(6/12, false); }
	.o-col-lg-5--no-padding { @include col(5/12, false); }
	.o-col-lg-4--no-padding { @include col(4/12, false); }
	.o-col-lg-3--no-padding { @include col(3/12, false); }
	.o-col-lg-2--no-padding { @include col(2/12, false); }
	.o-col-lg-1--no-padding { @include col(1/12, false); }
}

@include mq($br-medium) {
	.o-col-md-12 { @include col(12/12); }
	.o-col-md-11 { @include col(11/12); }
	.o-col-md-10 { @include col(10/12); }
	.o-col-md-9 { @include col(9/12); }
	.o-col-md-8 { @include col(8/12); }
	.o-col-md-7 { @include col(7/12); }
	.o-col-md-6 { @include col(6/12); }
	.o-col-md-5 { @include col(5/12); }
	.o-col-md-4 { @include col(4/12); }
	.o-col-md-3 { @include col(3/12); }
	.o-col-md-2 { @include col(2/12); }
	.o-col-md-1 { @include col(1/12); }
	.o-col-md-12--no-padding { @include col(12/12, false); }
	.o-col-md-11--no-padding { @include col(11/12, false); }
	.o-col-md-10--no-padding { @include col(10/12, false); }
	.o-col-md-9--no-padding { @include col(9/12, false); }
	.o-col-md-8--no-padding { @include col(8/12, false); }
	.o-col-md-7--no-padding { @include col(7/12, false); }
	.o-col-md-6--no-padding { @include col(6/12, false); }
	.o-col-md-5--no-padding { @include col(5/12, false); }
	.o-col-md-4--no-padding { @include col(4/12, false); }
	.o-col-md-3--no-padding { @include col(3/12, false); }
	.o-col-md-2--no-padding { @include col(2/12, false); }
	.o-col-md-1--no-padding { @include col(1/12, false); }
}

@include mq($br-med-small) {
	.o-col-mdsm-12 { @include col(12/12); }
	.o-col-mdsm-11 { @include col(11/12); }
	.o-col-mdsm-10 { @include col(10/12); }
	.o-col-mdsm-9 { @include col(9/12); }
	.o-col-mdsm-8 { @include col(8/12); }
	.o-col-mdsm-7 { @include col(7/12); }
	.o-col-mdsm-6 { @include col(6/12); }
	.o-col-mdsm-5 { @include col(5/12); }
	.o-col-mdsm-4 { @include col(4/12); }
	.o-col-mdsm-3 { @include col(3/12); }
	.o-col-mdsm-2 { @include col(2/12); }
	.o-col-mdsm-1 { @include col(1/12); }
	.o-col-mdsm-12--no-padding { @include col(12/12, false); }
	.o-col-mdsm-11--no-padding { @include col(11/12, false); }
	.o-col-mdsm-10--no-padding { @include col(10/12, false); }
	.o-col-mdsm-9--no-padding { @include col(9/12, false); }
	.o-col-mdsm-8--no-padding { @include col(8/12, false); }
	.o-col-mdsm-7--no-padding { @include col(7/12, false); }
	.o-col-mdsm-6--no-padding { @include col(6/12, false); }
	.o-col-mdsm-5--no-padding { @include col(5/12, false); }
	.o-col-mdsm-4--no-padding { @include col(4/12, false); }
	.o-col-mdsm-3--no-padding { @include col(3/12, false); }
	.o-col-mdsm-2--no-padding { @include col(2/12, false); }
	.o-col-mdsm-1--no-padding { @include col(1/12, false); }
}

@include mq($br-small) {
	.o-col-sm-12 { @include col(12/12); }
	.o-col-sm-11 { @include col(11/12); }
	.o-col-sm-10 { @include col(10/12); }
	.o-col-sm-9 { @include col(9/12); }
	.o-col-sm-8 { @include col(8/12); }
	.o-col-sm-7 { @include col(7/12); }
	.o-col-sm-6 { @include col(6/12); }
	.o-col-sm-5 { @include col(5/12); }
	.o-col-sm-4 { @include col(4/12); }
	.o-col-sm-3 { @include col(3/12); }
	.o-col-sm-2 { @include col(2/12); }
	.o-col-sm-1 { @include col(1/12); }
	.o-col-sm-12--no-padding { @include col(12/12, false); }
	.o-col-sm-11--no-padding { @include col(11/12, false); }
	.o-col-sm-10--no-padding { @include col(10/12, false); }
	.o-col-sm-9--no-padding { @include col(9/12, false); }
	.o-col-sm-8--no-padding { @include col(8/12, false); }
	.o-col-sm-7--no-padding { @include col(7/12, false); }
	.o-col-sm-6--no-padding { @include col(6/12, false); }
	.o-col-sm-5--no-padding { @include col(5/12, false); }
	.o-col-sm-4--no-padding { @include col(4/12, false); }
	.o-col-sm-3--no-padding { @include col(3/12, false); }
	.o-col-sm-2--no-padding { @include col(2/12, false); }
	.o-col-sm-1--no-padding { @include col(1/12, false); }
}
