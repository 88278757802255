// Font settings
$sans: 'MaisonNeue-Book', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;
$sansLight: 'MaisonNeue-Light', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;
$sansItalic: 'MaisonNeue-BookItalic', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;
$sansLightItalic: 'MaisonNeue-LightItalic', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;
$normal: 400;

// Typography
@mixin sans() {
	font-family: $sans;
	font-size: 28px;
	line-height: $baseline;
	@include mq($br-xlarge) {
		//font-size: 24px;
		font-size: 21px;
		line-height: 27px;
	}
	@include mq($br-large) {
		//font-size: 1.68vw;
		font-size: 1.46vw;
		line-height: $baseline;
	}
	@include mq($br-med-large) {
		font-size: 1.68vw;
		line-height: $baseline;
	}
	@include mq($br-medium) {
		font-size: 16px;
		line-height: $baseline;
	}
	@include mq($br-small) {
		font-size: 14px;
		line-height: $baseline;
	}
}

@mixin sans--large() {
	font-family: $sansLight;
	font-size: 2em;
	line-height: 1.19em;

	@include mq($br-med-large) {
		font-size: 1.75em
	}

	@include mq($br-small) {
		font-size: 1.625em;
		//font-size: 1.5em
	}
}

@mixin sans--small() {
	font-family: $sans;
	font-size: 0.6em;
	line-height: 1.25em;
	@include mq($br-medium) {
		font-size: 12px;
		line-height: 1.315em;
	}
	@include mq($br-small) {
		font-size: 11px;
		line-height: 1.315em;
	}
}

@mixin sans--italic() {
	font-family: $sansItalic;
}

@mixin sans--lightitalic() {
	font-family: $sansLightItalic;
}
