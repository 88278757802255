.o-banner {
	display:block;
	height: $padding*2;
}

.o-banner__list {
	height: 100%;
}

.o-banner__list li {
	display: table;
	height: 100%;
	float:left;

	a {
		display:block;
		width: 100%;
		height: 100%;
		padding-left: $padding/2;
	}

	&:first-child {
		a {
			padding-left: 0;
		}
	}
}

.o-banner__list li a {
	display: table-cell;
	vertical-align: middle;
	height: 100%;
	line-height: 1em;
}
