.c-splash {
  display: block;
	position: fixed;
  top:0;
	left: 0;
	right: 0;
	width: 100%;
  //height: 50vh;
  height: 27.5vw;

  background-color: $racinggreen;
  //background-color: lighten($racinggreen,5%);
  //@include transition(background-color, $normal, $ease);
  &.is-hidden {
    display:none;
    z-index:-9999;
    pointer-events: none;
  }

}

.c-splash__letters-s,
.c-splash__letters-n{
  //@include parent-behaviour();
  //height: 100%;
  //width: auto;
  @include transition(all, 0.800s, $ease);
  position: absolute;
  top: $padding;
  height: calc(100% - #{$padding*2});
  @include mq($br-med-small) {
    top: $padding/2;
    height: calc(100% - #{$padding});
  }
}

.c-splash__letters-s {
  left: $padding/1.5;
  @include mq($br-med-small) {
    left: $padding/3;
  }
}

.c-splash__letters-n {
  right: $padding/1.5;
  @include mq($br-med-small) {
    right: $padding/3;
  }
}

/*
.c-splash__letters-s {
  @include transform(translateX(100%));
}
*/

.c-splash__letter {
  position: relative;
  width:auto;
  height: 100%;
  float:left;
  padding-left: $padding/4;
  padding-right: $padding/4;
  @include mq($br-med-small) {
    padding-left: $padding/8;
    padding-right: $padding/8;
  }
}
/*
.c-splash__letter-s-01 {
  left: $padding;
  @include mq($br-med-small) {
    left: $padding/2;
  }
}

.c-splash__letter-s-02 {
  left: $padding*2;
  @include transform(translateX(100%));
  @include mq($br-med-small) {
    left: $padding;
  }
}

.c-splash__letter-n-01 {
  right: $padding*2;
  @include transform(translateX(-100%));
  @include mq($br-med-small) {
    right: $padding;
  }
}

.c-splash__letter-n-02 {
  right: $padding;
  @include mq($br-med-small) {
    right: $padding/2;
  }
}
*/

/*
.c-splash__letters-s,
.c-splash__letters-n{
  @include parent-behaviour();
  height: 100%;
  width: auto;
}

.c-splash__letters-s {
  @include transform(translateX(100%));
}

.c-splash__letter {
  position: absolute;
  width:auto;
  height: calc(100% - #{$padding*2});
  top: $padding;
  float:left;
  @include mq($br-med-small) {
    height: calc(100% - #{$padding});
    top: $padding/2;
  }
}

.c-splash__letter-s-01 {
  left: $padding;
  @include mq($br-med-small) {
    left: $padding/2;
  }
}

.c-splash__letter-s-02 {
  left: $padding*2;
  @include transform(translateX(100%));
  @include mq($br-med-small) {
    left: $padding;
  }
}

.c-splash__letter-n-01 {
  right: $padding*2;
  @include transform(translateX(-100%));
  @include mq($br-med-small) {
    right: $padding;
  }
}

.c-splash__letter-n-02 {
  right: $padding;
  @include mq($br-med-small) {
    right: $padding/2;
  }
}
*/

.c-splash__letter #letter #shape{
  fill: lighten($racinggreen, 5%);
}
