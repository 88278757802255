// Z-index

$z-below: -999;
$z-spawn: 1;
$z-spawn-hover: 2;
$z-section-page: 3;
$z-sticky: 	901;
$z-header: 	901;
$z-curtain: 902;
$z-overlay: 903;
$z-overlay-button: 904;
$z-screensaver: 999;
