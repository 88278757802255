// Animation

$fast			: 0.250s;
$normal		: 0.400s;
$slow			: 0.600s;
$ease			: cubic-bezier(0.445, 0.05, 0.55, 0.95); //easeInOutSine
//$ease: ease-in-out;

.fade-out { animation: fade-out $fast $ease forwards !important; }
.fade-in { animation: fade-in $fast $ease forwards; }

@keyframes fade-out {
    from { opacity: 1; } /* no comma here */
    to { opacity: 0; }
}

@keyframes fade-in {
    from { opacity: 0; } /* no comma here */
    to { opacity: 1; }
}
