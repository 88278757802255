.o-button {
  padding: $padding;
  border: none;
  cursor: pointer;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 0.75em;

}

//Arrows
.o-button--arrow-left {
  background-image: url('../img/arrow-left.svg');
  &--white {
    background-image: url('../img/arrow-left--white.svg');
  }
}

.o-button--arrow-right {
  background-image: url('../img/arrow-right.svg');
  &--white {
    background-image: url('../img/arrow-right--white.svg');
  }
}

.o-button--arrow-up {
  background-image: url('../img/arrow-up.svg');
  &--white {
    background-image: url('../img/arrow-up--white.svg');
  }
}

.o-button--arrow-down {
  background-image: url('../img/arrow-down.svg');
  &--white {
    background-image: url('../img/arrow-down--white.svg');
  }
}
