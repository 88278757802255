//General

html {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;

	background-color: $racinggreen;
}

*, *::before, *::after {
	box-sizing: inherit;
}

body.no-scroll {
	overflow: hidden;
}

.barba-container {
	position: relative;
}

.page-wrapper {
	//@include transform(translate3d(0,0,0));
	position:relative;
	-webkit-backface-visibility: hidden;
	//-webkit-font-smoothing: antialiased;
}

body.page-transition-helper {
	.page-wrapper {
		@include transform(translate3d(0,0,0));
		@include transition(transform, $normal, $ease);
	}
}

body.page-is-pushed {
	.page-wrapper {
		@include transform(translate3d(0,27.5vw,0));
	}
}
