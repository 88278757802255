.c-header-fixer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: $z-header;
	height:0;
}

.c-header {
	display:block;
	//height: $baseline*1.63;
	background-color: $white;

	height: $padding*2;

	@include transform(translate3d(0, 0, 0));
	@include transition(transform, $fast, $ease);

	&.is-hidden{
		@include transform(translate3d(0,-100%,0));
	}

	&.no-background {
		background-color: transparent;
	}

	&[data-textcolour="white"]{
		color: $white;
		ul, li, a {
			color: $white;
		}
	}

	&.has-scrolled-state {
		background-color: $white;
		color: $black !important;
		ul, li, a {
			color: $black !important;
		}
	}

}

.c-header__col{
	@include col(1/2);
	@include mq($br-small) {
		width: auto;
	}
}
