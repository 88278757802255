//Container
//Acts like a wrapper/inner div

.o-section {
	@include section();
	//-webkit-transform: translate3d(0,0,0);
	//-webkit-backface-visibility: hidden;
}

.o-section--padding {
	@include section--padding();
}

.o-section--padding--less {
	@include section--padding--less();
}

.o-section--padding--more {
	@include section--padding--more();
}

.o-section--page {
	z-index: $z-section-page;
	@include mq($br-medium) {
		padding-top: $padding*9;
	}
	@include mq($br-small) {
		padding-top: $padding*7;
	}
}

@media screen and (orientation:landscape) {
	.o-section--is-vh {
		//min-height: calc(100vh - #{$large_padding*4});
		min-height: calc(100vh);
	}
}

/*
.o-section__content {
	@include col(1);
	padding-left:0 !important;
	padding-right:0  !important;

	@include mq-min-w($br-medium) {
		width: 100%;
		margin: 0px auto;
		padding: 0;
		float:none;
	}
}
*/
