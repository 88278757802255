.c-navigation,
.c-navigation__list{
	height: 100%;
}

.c-navigation__author {
	display:none;
	opacity: 0;
}

@include mq-min-w($br-medium) {
	.c-navigation__list--site:hover,
	.c-navigation__list--site:focus {
		.c-navigation__logo {
			display:none;
			opacity: 0;
		}
		.c-navigation__author {
			display:inline-block;
			opacity: 1;
		}
	}
}

.c-navigation__languages {
	opacity: 0;
}

.c-navigation:hover,
.c-navigation:focus {
	.c-navigation__languages {
		opacity: 1;
	}
}

@include mq($br-med-small) {
	.c-navigation__item {
		width: 100%;
	}
	.c-navigation {
		.c-navigation__item--menu {
			 background-repeat: no-repeat;
			 background-position: center right;
			 background-size: 0.75em;
			 background-image: url('../img/arrow-down.svg');
		}
	}

	[data-textcolour="white"] .c-navigation {
		.c-navigation__item--menu {
			background-image: url('../img/arrow-down--white.svg');
		}
	}

	.c-navigation-mobile {
		.c-navigation__item--menu {
			background-repeat: no-repeat;
 		 	background-position: center right;
 		 	background-size: 0.75em;
 		 	background-image: url('../img/arrow-up--white.svg');
		}
	}

}
