.o-item {
	@include parent-behaviour();
	margin-bottom: $padding;
	@include mq($br-medium) {
		margin-bottom: $padding*1.5;
	}
}

.o-item__promotional {
	position: relative;
	display:block;
	-webkit-perspective: 1000;
	-webkit-backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;

	min-height: 5em;
	@include mq-min-w($br-med-small) {
		@include aspect-ratio(3,2);
	}

	@include mq($br-med-small) {
		margin-left: -$padding/2;
		margin-right: -$padding/2;
	}

}

.o-item__promotional__content {
	//background-color: rgba(240,240,240,1);
	position: absolute;
	top:0; left: 0; right:0; bottom:0;
	width: 100%;
	height: 100%;

	padding: $padding/1.5;

	@include transition(background-color, $fast, $ease, 0.1s);
	-webkit-transition: background-color $fast $ease 0.1s, color $fast $ease 0.1s;
	-moz-transition: background-color $fast $ease 0.1s, color $fast $ease 0.1s;
	-o-transition: background-color $fast $ease 0.1s, color $fast $ease 0.1s;
	transition: background-color $fast $ease 0.1s, color $fast $ease 0.1s;


	@include mq-min-w($br-medium) {
		background-color: rgba(240,240,240,1);
		&:hover {
			background-color: $racinggreen;
			color: $white;
		}
	}

}


.o-item__info {
	margin-top: $padding/3;
}
