//Container
//Acts like a wrapper/inner div

.o-container {
	@include container();
	padding-top: $padding/2;
	padding-bottom: $padding/2;
}

.o-container--less-padding {
	@include container();
	padding-top: $padding/4;
	padding-bottom: $padding/4;
}

.o-container--page {
	@include container();
	//padding-top: $padding;
	//padding-bottom: $padding;
}

.o-container-item {
	@include container-item();
}
