.o-image {
	position: relative;
	display:block;
	-webkit-perspective: 1000;
	-webkit-backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;

	.o-caption {
		opacity: 0;
	}

	.o-caption--visible {
		opacity: 1;
	}

	&:hover {
		.o-caption {
			opacity: 1;
		}
		.o-image__curtain {
			opacity: 1;
		}
	}

	@include mq($br-small) {
		.o-caption {
			opacity: 1;
		}
	}

}

.o-image--with-bg {
	background-color: $gray;
}

.o-image--with-bg img,
.o-image--with-fixed-ratio img {
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	width:100%;
}

// ASPECT RATIO

.o-image--is-landscape {
	@include aspect-ratio(3,2);
}

.o-image--is-portrait {
	@include aspect-ratio(2,3);
}

.o-image--is-square {
	@include aspect-ratio(1,1);
}

// IMAGE OVERLAY (AS HOVERSTATE)

.o-image__curtain {
	background-color: rgba(240,240,240,1);

	position: absolute;
	top:0; left: 0; right:0; bottom:0;
	width: 100%;
	height: 100%;

	padding: $padding/1.5;

	opacity: 0;
	@include transition(opacity, $fast, $ease, 0.1s);

}

//CONTAINERS
/*
.o-image-container,
.o-media-container {
	overflow: visible;
	padding-top: $padding;
	padding-bottom: $padding;
	padding-left: $padding;
	padding-right: $padding;
 	-webkit-perspective: 1000;
	-webkit-backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;
}
*/

.o-image-container--half,
.o-image-container--full {
	padding-top: $padding/2;
	padding-bottom: $padding/2;
	padding-left: $padding;
	padding-right: $padding;
	-webkit-perspective: 1000;
	-webkit-backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;
}

@include mq-min-w($br-small) {
	.o-image-container--half.is-left {
		padding-right: $padding/2;
	}

	.o-image-container--half.is-right {
		padding-left: $padding/2;
	}
}

//.o-image-container:first-child, :not(.o-image-container) + .o-image-container { padding-top: $padding; }

.o-image-container:last-of-type {
	padding-bottom: $padding;
}

//CAPTIONS

.o-caption,
figcaption {
	position: absolute;
	//position: relative;
	bottom:$padding;
	display:block;
	height: auto;
	padding-left: $padding*1.5;

	@include mq($br-medium) {
		padding-left: $padding*1.5;
	}

	@include mq($br-small) {
		padding-left: $padding*1;
	}

}

//Caption positioning

.o-caption--left,
figcaption.o-caption--left {
	float:left !important;
}

.o-caption--center,
figcaption.o-caption--center {
	float:none !important;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.o-caption--right,
figcaption.o-caption--right {
	float:right !important;
}
