//Overlay
.o-overlay {

	@include overlay(100%, 100%, $z-overlay, $racinggreen);
	@include transform(translateY(100%));
	@include transition(transform, $fast, $ease);

	&.is-animated,
	&.is-visible {
		pointer-events: auto;
		@include transform(translateY(0));
		@include transition(transform, $fast, $ease);
	}

}

.o-overlay--page {
	display:none;

	&.is-activated {
		display:block;
	}
}

.o-overlay__content {
	width:100%;
	height:100%;
	overflow: scroll;
}
