table {
	border-collapse: collapse;
	border-spacing: 0;
}

td, th {
	padding: 0;
}

.c-table__row {
	width: 100%;
	position: relative;
	vertical-align: top;
}

.c-table__date {
	width: auto;
	padding-left: 0;
  padding-right: $padding/2;
	vertical-align: top;
}

.c-table__text {
	width: auto;
	vertical-align: top;
}
