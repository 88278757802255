.c-navigation-mobile {

	@include overlay(50%, 100%, $z-overlay, $racinggreen);
	opacity: 0;
	@include transition(opacity, $normal, ease);

	width: calc(50% + #{$padding/2});

	@include mq(300px) {
		width: 100%;
	}

	height: 100%;

	right:0;
	left: auto;

	opacity: 0;

	&.is-toggled {
		pointer-events: auto;
		opacity: 1;
		@include transition(opacity, $normal, ease);
	}

}

.c-navigation-mobile__button {
	display:block;
	height: $padding*2;
}

.c-navigation-mobile__list {

	li {
		display:block;
		float:none;
	}

}

.c-navigation-mobile__list:first-of-type {
	position: absolute;
	top: $padding*2.275;
 	//padding-top: $padding*2.275;
	@include mq($br-small) {
		//padding-top: $padding*2.45;
		top: $padding*2.45;
	}
}

.c-navigation-mobile__list:last-of-type {
	position: absolute;
	bottom: $padding/2;
}
