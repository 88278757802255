.c-footer {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $z-below;
}

/*
body.is-mobile-device .c-footer{
	position: relative;
	left: auto;
	right: auto;
	bottom: auto;
}
*/

.c-footer-banner {
	margin-top: $padding;
	@include transition(all, $fast, $ease);
	@include transform(translateY(100%));
	opacity: 0;
	&.is-in-view {
		@include transform(translateY(0));
		opacity: 1;
	}
}

body.is-mobile-device .c-footer-banner{
	@include transform(translateY(0));
	opacity: 1;
}

.c-footer__btn-top {
	position: absolute;
	right: 0.25em;
}
