// Typography
// Typographical base selectors. Define base styles on single element selectors here.

body {
  @include sans();
  color: $fakeblack;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'kern' 1, 'liga' 1;
  -ms-font-feature-settings: 'kern' 1, 'liga' 1;
  -o-font-feature-settings: 'kern' 1, 'liga' 1;
  -webkit-font-feature-settings: 'kern' 1, 'liga' 1;
  font-feature-settings: 'kern' 1, 'liga' 1;
}

.tnum {
  -moz-font-feature-settings: 'kern' 1, 'liga' 1, 'tnum' 1;
  -ms-font-feature-settings: 'kern' 1, 'liga' 1, 'tnum' 1;
  -o-font-feature-settings: 'kern' 1, 'liga' 1, 'tnum' 1;
  -webkit-font-feature-settings: 'kern' 1, 'liga' 1, 'tnum' 1;
  font-feature-settings: 'kern' 1, 'liga' 1, 'tnum' 1;
  letter-spacing: -0.0125em;
}

a {
  color: $fakeblack;
  text-decoration: none;
  &:hover,
  &:focus,
  &.is-active,
  &.is-current {
    color: $fakeblack;
    text-decoration: none;
  }
}

.type--link-indent {
  display:inline-block;
  &:hover,
  &:focus,
  &.is-active,
  &.is-current {
      &::before {
        display:inline-block;
        float:left;
        content:'';
        width: 0.75em;
        height: 0.75em;
        margin-right: 0.125em;
        margin-top: 0.2em;
        border-radius:50%;

        background-color: #000000;

      }
  }
}

p a,
.o-text a,
.type--link {
  cursor: pointer;
  opacity: 0.5;
  @include transition(opacity, $fast, $ease);
  &:hover,
  &:focus,
  &.is-active,
  &.is-current {
    opacity: 1;
  }
}

a.is-current .type--link{
  opacity: 1;
}

.type--link-container {
  &:hover,
  &:focus,
  &.is-active,
  &.is-current {
    .type--link {
        background-size: 1px 1px;
        background-size: 1px calc(3% + 1px);
    }
    .type--link-indent::before {
        display:inline-block;
        float:left;
        content:'';
        width: 0.75em;
        height: 0.75em;
        margin-right: 0.125em;
        margin-top: 0.2em;
        border-radius:50%;

        background-color: #000000;
    }
  }
}

p {
  margin-bottom: 1.25rem;
  max-width: $max_text_width;
  &:last-child {
    margin-bottom: 0;
  }
}

// Titles
// Title classes

.o-title {
    margin-bottom: 1.25rem;
    &.no-padding {
    	margin-bottom: 0;
    }
}

.o-title--h1 {
	@include sans--large();
}

.o-title--h2 {
	@include sans();
}

.o-title--h3 {
	@include sans--small();
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  font-size: inherit;
}

sup {
  top: -0.75em;
  font-size: 50%;
}

b, strong {
  font-weight: normal;
  text-transform: uppercase;
  display:inline-block;
}



.type--large {
    @include sans--large();
    em,
    i,
    .type--italic {
      @include sans--lightitalic();
      font-weight: normal;
    }
}

.type--small,
.o-caption {
    @include sans--small();
}

em,
i,
.type--italic {
  @include sans--italic();
  font-weight: normal;
}

.type--uppercase {
  text-transform: uppercase;
}

.type--tag:after {
  content: ", ";
}
.type--tag:last-child:after {
  content: "";
}
