// Flickity

/*! Flickity v2.0.10
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */
/*
.flickity-prev-next-button {
  position: absolute;
  bottom: $padding/2;
  width: 44px;
  height: 44px;
  border: none;
  cursor: pointer;
  transform: translateY(-50%);
  transform: none;
}

.flickity-prev-next-button {
  @include transition(opacity, $fast, $ease);
  opacity: 0;
}

.c-slider:hover {
  .flickity-prev-next-button {
    opacity: 1;
  }
}

.flickity-prev-next-button.previous {
  left: auto;
  right: $padding*2;
}

.flickity-prev-next-button.next {
  right: $padding;
}


.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 16px;
  top: 16px;
  width: 16px;
  height: 16px;
}

.flickity-prev-next-button .arrow {
  fill: $white;
}
*/
//Slider
//Slider component

.c-slider {
  width: 100vw;
  height: 66.66vw;
  max-height: calc(100vh - #{$padding*2});

  background-color: $gray;
  color: #fff !important;

  opacity: 0;
  @include transition(opacity, $normal, $ease);

  @include mq($br-med-large) {
    height: 66.66vw;
    max-height: 100vh;
  }

  a, li ,p {
    color: #fff !important;  
  }
}

@media (orientation: portrait) {
  .c-slider {
    height: 66.66vw;
    max-height: 100vh;
  }
}


.c-slider.is-hidden {
  display: none;
}

.c-slider.flickity-enabled {
  opacity: 1;
}

.c-slider__cell {
  width: 100%;
  height:100%;
  display: flex;
  align-items: center;
}

.c-slider__caption {
  z-index: 2;
  position: absolute;
  left: $padding;
  bottom: $padding;
}

//Slider button
.c-slider__btn {
  z-index: 2;
  position: absolute;
  bottom: $padding/2;
  border: none;
  cursor: pointer;
  opacity: 1;
  @include transition(opacity, $fast, $ease);
  //opacity: 0;
}

.c-slider__btn--prev {
  left: auto;
  right: $padding*2;
  padding-right: $padding/1.5 !important;
  //background-color: #ff0000;
}

.c-slider__btn--next {
  right: $padding/2;
  padding-left: $padding/1.5 !important;
  //background-color: #0000ff;
}

.c-slider:hover {
  .c-slider__btn {
    opacity: 1;
  }
}

.c-slider__btn:disabled {
  opacity: 0.3;
  cursor: auto;
}
