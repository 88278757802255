.o-video {
  position: relative;
  overflow: hidden;
  display: block;
	width:100%;
	height:100%;
}

.o-video__controls {
  cursor: pointer;
	@include child-overlay-behaviour;
	background: url('../img/btn-play.svg') no-repeat center center;
	background-color: rgba(0,0,0,0.33);
  opacity: 1;
	@include transition(all, $fast, $ease);

	&:hover {
		background-color: rgba(0,0,0,0.2);
	}

	&.play {
		background-image: url('../img/btn-play.svg');
	}

	&.pause {
		background-image: url('../img/btn-pause.svg');
	}
}

.o-video__controls.has-played {
	opacity: 0;
	background-color: rgba(0,0,0,0.2);

}

.o-video__controls.init-hoverstate {
	opacity: 1;
}
